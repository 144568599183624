@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/bootstrap";

@import "~prismjs/themes/prism-tomorrow.css";
@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/plugins/diff-highlight/prism-diff-highlight.css";
@import "~prismjs/plugins/line-highlight/prism-line-highlight.css";
@import "~prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "~prismjs/plugins/match-braces/prism-match-braces.css";
@import '~font-awesome/css/font-awesome.css';

:root,
[data-bs-theme=light] {
	--ortus-green: #84cc16;

	--bg-primary: #0C4A6E;
	--text-color: #4b5563;
	
	--bg-btn-primary: var(--ortus-green);
	--bg-btn-primary-hover: #6da813;
	--bg-btn-secondary: #ecfccb;

	--bg-copy-right: #075985;
	--text-footer-color: #ffffff;
	--link-color: #ffffff;

	--bs-link-color: var(--link-color);
	--bs-link-color-rgb: 255, 255, 255;

	--bs-nav-link-color: var(--link-color);
	--bs-nav-link-color-rgb: 255, 255, 255;
}

@include color-mode(dark) {}

@include color-mode(light) {}